import React, { useEffect, useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TrattamentoHeroContainer from "../../containers/trattamenti/trattamento-hero"
import TrattamentoMenu from "../../components/TrattamentoMenu"
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchTrattamento,
  selectTrattamentoPage,
  selectTrattamentoPageLoading,
} from "../../redux/trattamentoPageSlice"
import LoadingView from "../../components/LoadingView/LoadingView"
import withLocation from "../../utils/withLocation"
import TrattamentoArtiContainer from "../../components/TrattamentoArtiContainer/TrattamentoArtiContainer"

const TrattamentoArticoliPage = ({ pageContext: { tratt }, location }) => {
  const crumbs = [
    {
      crumbLabel:
        tratt.macrocategoria === "CHIRURGIA ESTETICA"
          ? "Chirurgia Estetica"
          : "Medicina Estetica",
      pathname:
        tratt.macrocategoria === "CHIRURGIA ESTETICA"
          ? "/chirurgia-estetica"
          : "/medicina-estetica",
      origin: location.origin,
    },
    {
      crumbLabel: tratt.nome,
      pathname:
        "/" +
        [
          location.pathname.split("/")[1],
          location.pathname.split("/")[2],
          location.pathname.split("/")[3],
        ].join("/"),
    },
    {
      crumbLabel: "Articoli",
      pathname: location.pathname,
    },
  ]

  const loading = useSelector(selectTrattamentoPageLoading)
  const trattamento = useSelector(selectTrattamentoPage)
  const dispatch = useDispatch()

  useEffect(() => {
    if (trattamento?.id_sys != tratt.id) {
      dispatch(fetchTrattamento(tratt.id))
    }
  }, [tratt.id])

  if (loading) {
    return <LoadingView />
  }
  return (
    <Layout>
      <SEO title={`${trattamento.trattamento} - Articoli`} />
      <BreadCrumbs crumbs={crumbs} />
      <TrattamentoHeroContainer tra={trattamento} />
      <TrattamentoMenu active={6} trattamento={tratt} />
      <TrattamentoArtiContainer title={trattamento.trattamento} />
    </Layout>
  )
}

export default withLocation(TrattamentoArticoliPage)
